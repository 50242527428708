import React, { Component } from 'react';


export default class Link extends Component {
    render() {
        return (
            <div className='smartLink-container'>
            <div className='smartLink-image' style={{backgroundImage:`url(${this.props.image})`}}></div>
            <div className='smartLink-title'>{this.props.title}</div>
            <div className='smartLink-subtitle'>Choose music service</div>
            {this.props.links.map((link) =>
                <a className="smartLink-button" target="_blank" href={link.link}>
                <div className={`smartLink-icon-${link.type === 'apple music' ? 'appleMusic' : link.type}Icon`}></div>
                <span className='smartLink-button-text'>
                    {link.type}
                </span>
                </a>
            )}
            </div>
        )
    }
}