import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import '../style/yr1style.css';
import '../style/style.scss';
import Link from '../components/link';
import Malibou from '../images/sounds-malibu.jpg';
import merely from '../images/sounds-merely.jpg';
import Sound from '../images/sounds-2020.jpg';
import Catalog from '../images/catalogue-2020.jpg';
import Dungen from '../images/dungen_sounds-v2-2.jpg';

class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opacity: 1
    }
  } 

  componentDidMount(){
    if (typeof window !== `undefined`) {
      window.addEventListener('scroll', this.handleScroll);
    }
  }
  componentWillUnmount(){
    if (typeof window !== `undefined`) {
      window.removeEventListener('scroll', this.handleScroll);
    }
  }

  handleScroll = (e) => {
      this.setState({opacity: 1 - window.scrollY / 500})
  }

  render() {
    const { allMdx } = this.props.data    
    const playlists = allMdx.nodes
    console.log(playlists)
    return (
      <div className="holder">
      <Layout location={this.props.location}>
        <SEO
          title="Home"
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        {playlists.map(playlist => 
          <Link 
            image={playlist.frontmatter.image} 
            title={playlist.frontmatter.title}
            links={playlist.frontmatter.links}  
          />
        )}
        {/* <Link 
          image={Dungen} 
          title={'YEAR0001 SOUNDS: GUSTAV EJSTES (DUNGEN)'} 
          link={'https://open.spotify.com/playlist/53QeNTWqSYsSRuow6FaX9F?si=Aw9_EaD6QOmYxovSYF1qEg'} 
        />
        <Link 
          image={Sound} 
          title={'YEAR0001 SOUNDS'} 
          link={'https://open.spotify.com/playlist/6vUT6jV502pZ2Pq9enNj0X?si=4nvNz90TSVqUd_sGbztCzA'} 
        />
        <Link 
          image={Catalog} 
          title={'YEAR0001 CATALOGUE'} 
          link={'https://open.spotify.com/playlist/3gMeqxjhbNi2si0QM0Dmfl?si=YPe6BmW6QmWHoqaAwrWkWg'} 
        /> */}
          <div style={{opacity: this.state.opacity}} className="scroll-tag">
            SCROLL DOWN FOR MORE
          </div>
      </Layout>
      </div>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query BlogPostBySlug {
    site {
      siteMetadata {
        title
        author
      }
    }
    allMdx(sort: {fields: frontmatter___date, order: DESC}) {
      nodes {
        frontmatter {
          date
          image
          title
          links {
            link
            type
          }
        }
      }
    }
  }
`
